import React from "react"

const InterviewDetail = ({ title, extLink, text, media, dateStr, extLinkButtonText, i }) => (
  <div class="portfolio-modal modal fade" id={`interview${i+1}`} tabIndex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="close-modal" data-dismiss="modal">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 mx-auto">
              <div class="modal-body">
                <h3 class="text-uppercase">{title}</h3>
                <p class="item-intro text-muted">{media}</p>
                <p class="item-intro text-muted">{dateStr}</p>
                {
                  text.map((v, j) => <p key={`${i}interviewtext${j}`}>{v}</p>)
                }
                <a href={extLink} target="_blank"><button class="btn btn-primary" type="button">
                  {extLinkButtonText ? extLinkButtonText : "閱讀全文（" + media + "）"}</button></a>
                <button class="btn btn-secondary" data-dismiss="modal" type="button">
                  <i class="fas fa-times"></i>
                  返回</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default InterviewDetail