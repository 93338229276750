import React from "react"

const RecentPost = ({title, imgSrc, dateStr, i}) => (
  <div class="col-md-4 col-sm-6 portfolio-item">
    <a class="portfolio-link" data-toggle="modal" href={`#recentPubModal${i+1}`}>
      <div class="portfolio-hover">
      </div>
      <img class="img-fluid" src={imgSrc} alt="" />
    </a>
    <div class="portfolio-caption">
      <h4>{title}</h4>
      <p class="text-muted">{dateStr}</p>
    </div>
  </div>
)

export default RecentPost