import React from "react"
import { Helmet } from "react-helmet"

import RecentPost from "../components/recentpost"
import RecentPostDetail from "../components/recentpostdetail"

import Interview from "../components/interview"
import InterviewDetail from "../components/interviewdetail"

import "../styles/bootstrap.css"
import "../styles/all.css"
import "../styles/agency.css"

const IndexPage = ({ data }) => (
  <>
    <Helmet>
      <script src="/vendor/jquery/jquery.min.js" />
      <script src="/vendor/bootstrap/js/bootstrap.bundle.min.js" />
      <script src="/vendor/jquery-easing/jquery.easing.min.js" />
      <script src="/js/agency.js" />
    </Helmet>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand js-scroll-trigger" href="#page-top">公共衞生研究社</a>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          Menu
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav text-uppercase ml-auto">
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#about">簡介</a>
            </li>
            <li class="nav-item">
                <a class="nav-link js-scroll-trigger" href="#recentpub">近期文章</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#interview">傳媒訪問</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#publication">出版物</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#contact">聯絡我們</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="/en">EN</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  
    <header class="masthead">
      <div class="container">
        <div class="intro-text">
          <a class="btn btn-primary text-uppercase js-scroll-trigger" href="#recentpub">近期文章</a>
        </div>
      </div>
    </header>
  
    <section class="bg-light page-section" id="recentpub">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h3 class="section-heading text-uppercase">近期文章</h3>
            </div>
          </div>
          <div class="row justify-content-md-center">
            {
              data.allRecentPost.nodes.map((v, i) => 
                <RecentPost key={`recentPubModal${i+1}`} title={v.title} imgSrc={v.imgSrc} dateStr={v.dateStr} i={i}/>
              )
            }
          </div>
        </div>
      </section>
  
      <section class="bg-light page-section" id="interview">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h3 class="section-heading text-uppercase">傳媒訪問</h3>
            </div>
          </div>
          <div class="row justify-content-md-center">
            {
              data.allInterview.nodes.map((v, i) => 
                <Interview key={`interview${i+1}`} title={v.title} imgSrc={v.imgSrc} 
                  media={v.media}
                  dateStr={v.dateStr} i={i}/>
              )
            }
          </div>
        </div>
      </section>
  
    <section class="page-section" id="publication">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="section-heading text-uppercase">出版物</h3>
          </div>
        </div>
        <div class="row text-center justify-content-md-center">
          <div class="col-md-4">
            <img class="img-fluid" src="img/Medical_Cover-preview-07-sm.jpg" alt="Book Cover" />
            <h4 class="service-heading">《你個醫療制度壞咗呀！》</h4>
            <p class="text-muted">透過採訪醫療系統內的「人」：由政策制訂者、醫院管理層、前線醫生、護士、以及各式專職醫療，從而讓大眾理解，香港醫療敗壞的遠因和近因，同時亦探討挽回劣勢的機會。
            </p>
          </div>
        </div>
      </div>
    </section>
  
    <section class="page-section" id="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="section-heading text-uppercase">簡介</h3>
            <h3 class="section-subheading text-muted">公共衞生研究社（Public Health Research Collaborative）是由民間自發，於2019年成立的研究組織。我們希望透過實證為本的研究和考察，探討香港的醫療及公共衞生發展，並推動本地的健康素養和參與衞生政策倡議。</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ul class="timeline">
              <li>
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" src="img/begin.jpg" alt="" />
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>2019年初</h4>
                    <h4 class="subheading">成立</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted"></p>
                  </div>
                </div>
              </li>
  
              <li class="timeline-inverted">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" src="img/Medical_Cover-preview-07-ting-sq.jpg" alt="" />
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>2019年7月</h4>
                    <h4 class="subheading">出版《你個醫療制度壞咗呀！》</h4>
                  </div>
                </div>
              </li>
                
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section class="page-section" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="section-heading text-uppercase">聯絡我們</h3>
            <h3 class="section-subheading text-light">Email: contact@hkphrc.com</h3>
          </div>
        </div>
      </div>
    </section>
  
    <footer class="footer" id="footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-4">
            <span class="copyright">Copyright &copy; 公共衞生研究社 2020</span>
          </div>
          <div class="col-md-4">
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <a href="https://medium.com/@hkphrc" target="_blank">
                  <i class="fab fa-medium"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://facebook.com/hkphrc" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  
    {
      data.allRecentPost.nodes.map((v, i) => 
        <RecentPostDetail key={`recentPubModal${i+1}`} title={v.title} text={v.text} extLink={v.extLink} dateStr={v.dateStr} i={i}/>
      )
    }
    {
      data.allInterview.nodes.map((v, i) => 
        <InterviewDetail key={`interview${i+1}`} title={v.title} media={v.media} text={v.text} extLink={v.extLink} dateStr={v.dateStr} extLinkButtonText={v.extLinkButtonText} i={i}/>
      )
    }
    </>
)

export default IndexPage


export const IndexQuery = graphql`
  query MyQuery {
    allRecentPost {
      nodes {
        id
        imgSrc
        dateStr
        text
        title
        extLink
      }
    }
    allInterview {
      nodes {
        id
        imgSrc
        extLink
        dateStr
        media
        text
        title
        extLinkButtonText
      }
    }
  }
`
